/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Title, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Subtitle, Text, PriceWrap, Image, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
          
          <Column className="--menu --style4 pb--12 pl--12 pr--12 pt--12" menu={true}>
            
            <Menu className="--full" fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box">
                
                <Title className="title-box fs--43 w--300 title-box--invert" content={"A-studio"}>
                </Title>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 fs--22 w--300 pl--10" href={"#sluzby"} target={""} content={"Služby"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 w--300 pl--10" href={"#cenik"} target={""} content={"Ceník"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 w--300 pl--10" href={"#kosmetika"} target={""} content={"Kosmetika"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--filling2 w--300 pl--10" href={"#kontakt"} target={""} content={"Kontakt"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-j8ejmt bg--top --full" name={"hlavicka"} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11524/0b35fe7fb27e4d9c8c34c60d5b35bf79_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11524/0b35fe7fb27e4d9c8c34c60d5b35bf79_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11524/0b35fe7fb27e4d9c8c34c60d5b35bf79_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11524/0b35fe7fb27e4d9c8c34c60d5b35bf79_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11524/0b35fe7fb27e4d9c8c34c60d5b35bf79_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11524/0b35fe7fb27e4d9c8c34c60d5b35bf79_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11524/0b35fe7fb27e4d9c8c34c60d5b35bf79_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11524/0b35fe7fb27e4d9c8c34c60d5b35bf79_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pt--20 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--style8 subtitle-box--center ff--2 fs--30 w--300 subtitle-box--invert ls--50 mt--25" content={"PERMANENTNÍ MAKE UP &amp; KOSMETIKA"}>
              </Subtitle>

              <Title className="title-box fs--185 w--300 title-box--invert" style={{"marginTop":35}} content={"A-Studio"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--style6 subtitle-box--center ff--2 fs--30 w--300 subtitle-box--invert ls--50 lh--1 mt--10" content={""}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn5 ls--08 mt--50" href={"#kontakt"} content={"Rezervace"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-m7yerb pb--80 pt--60" name={"info"} style={{"marginTop":0,"backgroundColor":"rgba(27, 26, 31, 1)"}} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11524/bc09f771a4aa423ea8c41b4c52260cf6_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11524/bc09f771a4aa423ea8c41b4c52260cf6_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11524/bc09f771a4aa423ea8c41b4c52260cf6_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11524/bc09f771a4aa423ea8c41b4c52260cf6_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11524/bc09f771a4aa423ea8c41b4c52260cf6_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11524/bc09f771a4aa423ea8c41b4c52260cf6_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11524/bc09f771a4aa423ea8c41b4c52260cf6_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/11524/bc09f771a4aa423ea8c41b4c52260cf6_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--right mt--10 pr--20">
              
              <Title className="title-box fs--48 w--300 title-box--invert" content={"Ulehčete si práci s každodenním líčením.\n"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shadownull" href={"#cenik"} content={"Ceník služeb"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10 pr--20">
              
              <Subtitle className="subtitle-box w--300 subtitle-box--invert" content={"Permanentní make-up"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300 subtitle-box--invert" content={"— Make-up horních či dolních očních linek, obočí, kontury rtů"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300 subtitle-box--invert" content={"— Podtrhuje rysy obličeje, koriguje nedostatky"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300 subtitle-box--invert" content={"— Konzultace, výběr odstínů, anestezie (znecitlivující mast LTB)<br>— Lze kombinovat<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--0 pb--20 pt--60" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --center el--2 mb--20 flex--stretch" columns={"2"}>
            
            <ColumnWrapper className="--hvr4 mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"rgba(247, 247, 247, 1)"}}>
              
              <Title className="title-box" content={"Malé lázně v salonu"}>
              </Title>

              <Text className="text-box mb--30" content={" Příprava na léto. Tělové ošetření s širokým záběrem účinků.&nbsp;Celotělový zábal Marine Essence -&nbsp;Pokožka po zábalu jemná, měkká, obohacená výživnými látkami a minerály.\n\nZároveň bude pevná s vyrovnanou vlhkostí.<br><br>Ideální kůra je 5-10 zábalu v týdenním intervalu.\n\nTělo se zbavuje odpadních látek, obohacuje se o vitamíny, minerály ,stopové prvky a základní aminokyseliny. Zároveň se aktivizuje imunitní systém, všeobecně se zlepšuje zdravotní stav.<br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Podpora zeštíhlení"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Intenzivní regenerace"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Hydratace pokožky"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300 mb--0 pb--16" content={"— Dodání energie<br>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadownull mt--40 pb--0" href={"#kontakt"} content={"Zamluvit termín"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr4 mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"rgba(247, 247, 247, 1)"}}>
              
              <Title className="title-box" content={"Permanentní make-up"}>
              </Title>

              <Text className="text-box mb--30" content={"Permanentní make-up (PM) je metoda líčení s dlouhodobým efektem. Šetrným způsobem se pomocí pigmentovacího přístroje implantují do horní vrstvy pokožky přírodní barviva (v místě obočí, na oční víčka nebo rty)."}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Permanentní make-up"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Make-up horních či dolních linek, obočí, kontury rtů"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Podtrhuje rysy obličeje, koriguje nedostatky"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300 mb--0 pb--16" content={"— Konzultace, výběr odstínů, anestezie (znecitlivující mast LTB)<br>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadownull mt--40 pb--0" href={"#kontakt"} content={"Zamluvit termín"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr4 mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"rgba(247, 247, 247, 1)"}}>
              
              <Title className="title-box" content={"CryoPen"}>
              </Title>

              <Text className="text-box mb--25" content={"Kryochirurgické pero CryoPen nabízí moderní technologické řešení kryoterapie pro odstranění nevzhledných kožních útvarů, které chcete odstranit z kosmetických, nebo zdravotních příčin.<br><br>Díky tenkému proudu kryogenu lze odstranit i velmi malé útvary bez poškození okolní tkáně a bez rizika vzniku jizev.&nbsp;<br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"—&nbsp;Odstraníme přesně danou lézi bez bolesti a poškození okolní tkáně."}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Mikro-proud kryogenu (N2O) o teplotě -89°C míří pod vysokým tlakem přesně na lézi, kterou chcete odstranit."}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Penetruje do tkáně rychlostí 1 mm za 5 sekund. Útvar ve velikosti 2 mm tedy odstraníte za 10 sekund.\n"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadownull mt--25" href={"#kontakt"} content={"Zamluvit termín"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr4 mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"rgba(247, 247, 247, 1)"}}>
              
              <Title className="title-box" content={"Laminace obočí"}>
              </Title>

              <Text className="text-box mb--30" content={"Laminace neboli Bio fixace obočí je unikátní technika pro zvýraznění, posílení, zafixování a narovnání obočí."}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Větší objem, optické zahuštění a výživa obočí"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Posílení poškozených chloupků. Vytvarování obočí"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Fixace obočí a barvy"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Výdrž až 4 týdny<br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300 pb--10" content={"— Okamžitý výsledek<br>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadownull mt--80" href={"#kontakt"} content={"Zamluvit termín"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr4 mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"rgba(247, 247, 247, 1)"}}>
              
              <Title className="title-box" content={"Lash lifting &amp; Botox na obočí"}>
              </Title>

              <Text className="text-box mb--10" content={"Šetrné natočení řas a hloubková výživa obočí pro přirozeně natočené, silnější, hustší a opticky prodloužené a načerněné obočí."}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Větší natočení a delší objem řas&nbsp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Dlouhodobě zlepšuje kvalitu přírodních řas"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Hypoalergenní, nepoužívá se žádné lepidlo jako u prodlužování čas"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Přírodní řasa se stává silnější a objemnější až o 30–50%"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300 pb--16" content={"— Efekt vydřží 6-8 týdnů."}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadownull mt--25" href={"#kontakt"} content={"Zamluvit termín"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr4 mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"rgba(247, 247, 247, 1)"}}>
              
              <Title className="title-box" content={"Novinka ! Baby botox\n\n"}>
              </Title>

              <Text className="text-box mb--10" content={"Wrinkle rubber botox 24gold, se používá v malé dávce mikrojehliček koktejlu s kosmetickým botoxem, aby bylo možné přizpůsobit jemné a přirozené výsledky které neomezují pohyby obličeje. Je to vynikající metoda k omlazení pleti a je obzvláště oblíbená u klientů. Pokud hledáte přirozeně vypadající, účinné omlazení obličeje, je tu pro Vás právě tato metoda."}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Báječná metoda k ošetření vrásek, povislé pleti a kontur"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Jemné a přirozené omlazení obličeje"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Wrinkle rubber botox 24gold - FDA schválené zařízení pro profesionální použití"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Patentovaný 24K pozlacený mikroinjekční systém"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300 pb--16" content={"— Výsledky vydřží až 8 měsíců."}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadownull mt--25" href={"#kontakt"} content={"Zamluvit termín"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--10" name={"cenik"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0" columns={"1"}>
            
            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--62 w--300 lh--12" content={"Ceník"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--0" name={"cenik"} style={{"marginTop":36.1875}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box pb--10" content={"<span style=\"color: rgb(121, 121, 121); font-weight: 400;\">Malé lázně</span><br>"}>
              </Subtitle>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Celotělový zábal Marine Essence - 1 ošetření"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"2190 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <Text className="text-box fs--14" content={"<span style=\"font-style: italic; color: rgb(233, 233, 233);\">*Doba aplikace 25-45 min.</span>"}>
              </Text>

              <Subtitle className="subtitle-box pb--10" content={"<span style=\"color: rgb(121, 121, 121); font-weight: normal;\">Novinka! Baby botox</span>"}>
              </Subtitle>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Čelo"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"1599 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Vrásky kolem obou očí"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"1599 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Celý obličej"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"3999 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <Subtitle className="subtitle-box fs--16 lh--18" content={"<span style=\"font-style: italic; font-weight: normal;\"><span style=\"color: rgb(194, 194, 194);\">* Doba aplikace 25-45 min.</span></span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box pb--10" content={"<span style=\"color: rgb(121, 121, 121); font-weight: normal;\">Permanentní make-up</span>"}>
              </Subtitle>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"PMU horní nebo dolní linky"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"1200 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"PMU obočí - Ombre"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"2500 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"PMU obočí -&nbsp; Vláskování"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"3200 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"PMU rty - kontura"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"3000 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"PMU rty - výplň"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"2000 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"PMU rty - kontura s výplní"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"5000 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box pb--10" content={"<span style=\"color: rgb(121, 121, 121); font-weight: normal;\">Kosmetika</span>"}>
              </Subtitle>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Čištění pokožky přístrojem Micro Bubble"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"1500 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Minerální alginatní maska"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"800 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Kompletní ošetření pokožky - čištění + maska"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"1200 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Lash lifting řas"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"900 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Laminace obočí"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"800 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Mezoterapie/mikrojehličkování přístrojem dermapen<br>(Cena za 1 oblast - oblicej, krk, dekolt)<br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"2500 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box pb--10" content={"<span style=\"color: rgb(121, 121, 121); font-weight: normal;\">Odstranění kožních výrustků</span>"}>
              </Subtitle>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"1x"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"300 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"3x"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"700 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"5x"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"1000 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box pb--10" content={""}>
              </Subtitle>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"7x"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"1500 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"10x"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"2000 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Každý další zákrok"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"200 Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--40 pb--0 pt--0" name={"galerie"} layout={"l12"}>
        </Column>


        <Column className="pb--40 pt--40" name={"1g3qi3852hk"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11524/3330273de2e94361b71b99547bbdd489_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11524/3330273de2e94361b71b99547bbdd489_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11524/3330273de2e94361b71b99547bbdd489_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11524/3330273de2e94361b71b99547bbdd489_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11524/3330273de2e94361b71b99547bbdd489_s=1400x_.jpg 1400w"} position={{"x":"-80.12048192771083%","y":"-3.6144578313253053%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11524/4084f189e55941fc89ca6e694e46132f_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11524/4084f189e55941fc89ca6e694e46132f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11524/4084f189e55941fc89ca6e694e46132f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11524/4084f189e55941fc89ca6e694e46132f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11524/4084f189e55941fc89ca6e694e46132f_s=1400x_.jpg 1400w"} position={{"x":"-69.57831325301204%","y":"-2.4096385542168677%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11524/c7c294ca9c6c4a6c9be7924d313f885e_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11524/c7c294ca9c6c4a6c9be7924d313f885e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11524/c7c294ca9c6c4a6c9be7924d313f885e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11524/c7c294ca9c6c4a6c9be7924d313f885e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11524/c7c294ca9c6c4a6c9be7924d313f885e_s=1400x_.jpg 1400w"} position={{"x":"-95.48192771084338%","y":"-2.409638554216868%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11524/592b79dfd824443a94487c73943e537a_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11524/592b79dfd824443a94487c73943e537a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11524/592b79dfd824443a94487c73943e537a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11524/592b79dfd824443a94487c73943e537a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11524/592b79dfd824443a94487c73943e537a_s=1400x_.jpg 1400w"} position={{"x":"-60.24096385542171%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11524/b7a31c4c8313471cba40a691c1fbd7e1_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11524/b7a31c4c8313471cba40a691c1fbd7e1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11524/b7a31c4c8313471cba40a691c1fbd7e1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11524/b7a31c4c8313471cba40a691c1fbd7e1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11524/b7a31c4c8313471cba40a691c1fbd7e1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11524/b7a31c4c8313471cba40a691c1fbd7e1_s=2000x_.jpg 2000w"} position={{"x":"-69.42168674698794%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11524/37f1ec841f494ddfaf6e8d70045aa041_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11524/37f1ec841f494ddfaf6e8d70045aa041_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11524/37f1ec841f494ddfaf6e8d70045aa041_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11524/37f1ec841f494ddfaf6e8d70045aa041_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11524/37f1ec841f494ddfaf6e8d70045aa041_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11524/37f1ec841f494ddfaf6e8d70045aa041_s=2000x_.jpg 2000w"} position={{"x":"-30.120481927710845%","y":"-81.10843373493977%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11524/e809d9d4c2844bde9bc51aa037e4dc0a_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11524/e809d9d4c2844bde9bc51aa037e4dc0a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11524/e809d9d4c2844bde9bc51aa037e4dc0a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11524/e809d9d4c2844bde9bc51aa037e4dc0a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11524/e809d9d4c2844bde9bc51aa037e4dc0a_s=1400x_.jpg 1400w"} position={{"x":"-100%","y":"-7.915662650602403%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11524/3e7e8a478b244e918a1b47bc2ad137f8_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11524/3e7e8a478b244e918a1b47bc2ad137f8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11524/3e7e8a478b244e918a1b47bc2ad137f8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11524/3e7e8a478b244e918a1b47bc2ad137f8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11524/3e7e8a478b244e918a1b47bc2ad137f8_s=1400x_.jpg 1400w"} position={{"x":"-100%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" name={"kosmetika"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--48 w--300 ls--001 lh--12" style={{"maxWidth":1000}} content={"Kosmetika"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":950}} content={"Obličej představuje citlivou oblast, která je neustále vystavována vnějším faktorům a odráží naše zdraví i způsob života. Přípravky francouzské kosmetiky GERnétic účinkují optimálním způsobem v různých vrstvách pokožky, aktivní látky cíleně míří na odpovídající buňky a díky tomu dochází ke stimulaci přirozené obnovující schopnosti pokožky.<br><br>Pečující přípravky GERnétic působí v harmonii s pokožkou, zaměřuji se na individuální stav, akné, mastnou pleť, vrásky, citlivost, zčervenání nebo pigmentaci, a přinášejí tak mimořádné výsledky.&nbsp;&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"09nj875mt5q2"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"Čištění pokožky přistrojem Micro Babble\n"}>
              </Title>

              <Text className="text-box" content={"Přístroj vakuového hydropeelingu se používá k pravidelné péči o problematickou pokožku obličeje, zároveň i během terapie proti stárnutí.<br><br>Během jedné procedury je pokožka obličeje nebo těla aktivně nasycena sérovými výživami, jemně broušena a očištěna pomofskljdhfcí vakua. V důsledku komplexních vlivů lze dosáhnout řady pozitivních efektů.&nbsp;<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"Mineralní alginatové masky"}>
              </Title>

              <Text className="text-box" content={"Omlazující efekt alginátových masek je natolik výrazný, že po prvním použití nevyměníte tento prostředek za žádný jiný. Alginátové masky se vyrábí z hnědých řas, které obsahují velmi prospěšnou látku pro kůži, kyselinu alginátovou."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--40" name={"kontakt"} style={{"backgroundColor":"rgba(17, 16, 17, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box pb--0" content={"<span style=\"color: rgb(222, 222, 222);\">Kontaktujte nás</span>"}>
              </Title>

              <Text className="text-box pb--20" content={"<span style=\"color: rgb(202, 194, 194);\">Zašlete nám svůj dotaz či nás kontaktujte pro rezervaci termínu. Můžete nás také kontaktovat na tel. čísle +420 776 776 790, nebo na email: kuranova@gmail.com</span><br>"}>
              </Text>

              <ContactForm className="--shape2 --style3 --right ff--1 fs--16" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l3"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"paticka"}>
          
          <ColumnWrap className="column__flex el--4" columns={"4"}>
            
            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--48 w--300 lh--12" content={"Adresa"}>
              </Title>

              <Text className="text-box" content={"Zlatnická 1<br>Praha 1,&nbsp;110 00<br>Česká republika"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--48 w--300 lh--12" content={"Kontakt:"}>
              </Title>

              <Text className="text-box" content={"+420 776 776 790<br>kuranova@gmail.com<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right mt--10">
              
              <Text className="text-box ff--3 fs--24 mt--0" content={"<a href=\"#cenik\">S</a><a href=\"#sluzby\">lužby</a>"}>
              </Text>

              <Text className="text-box ff--3 fs--24 mt--0" content={"<a href=\"#cenik\">Ceník</a>"}>
              </Text>

              <Text className="text-box ff--3 fs--24 mt--0" content={"<a href=\"#kosmetika\">Kosmetika</a><br>"}>
              </Text>

              <Text className="text-box ff--3 fs--24 mt--0" content={"<a href=\"#kontakt\">Kontakt</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}